import Categories from '../../components/Categories/Categories';
import Subcategories from '../../components/Subcategories/Subcategories';
import Products from '../../components/Products/Products';

import './Catalog.scss'

function Catalog() {
    return (
        <section className="catalog">
            <h2 className="title">Каталог</h2>
            <div className="catalog-grid">
                <div className="catalog-categories">
                    <Categories />
                </div>
                <div className="catalog-products">
                    <Products />
                </div>
                <div className="catalog-subcategories">
                    <Subcategories />
                </div>
            </div>

        </section>
    );
}

export default Catalog;