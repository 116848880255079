import { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import config from '../../config';

import baseImg from '../../images/base.jpeg'
import loading from '../../images/loading.gif'
import { checkProductImage } from "./productsThunks";

function ProductItem(props) {
    const { id, name, weight, description, price, imgName } = props
    const dispatch = useDispatch();
    const [imageSrc, setImageSrc] = useState(loading);
    const mediaUrl = config.mediaUrl;

    return (
        <div className="products-row">
            <div className='products-col'>
                <div className='swap-btn'></div>
            </div>
            <div className='products-col'>{name}</div>
            <div className='products-col'>{weight}</div>
            <div className='products-col'>{description}</div>
            <div className='products-col'>{price}</div>
            <div className='products-col'>
                <div className="products-col-img">
                    <img src={imgName === null ? baseImg : `${mediaUrl}public/products/${imgName}`} alt={name} />
                </div>
            </div>
            <div className='products-col'>
                <Link to={`/catalog/products/${id}`} className='icon-edit edit-btn products-btn' />

            </div>
        </div>
    );
}

export default ProductItem;