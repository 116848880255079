import { Routes, Route, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import Dashboard from '../../pages/Dashboard/Dashboard';
import Catalog from '../../pages/Catalog/Catalog';
import Navigation from '../Navigation/Navigation';
import Header from '../Header/Header'
import CategoryAdd from '../EntityEditors/CategoryEditor/CategoryAdd';

import SubcategoryAdd from '../EntityEditors/SubcategoryEditor/SubcategoryAdd';
import ProductAdd from '../EntityEditors/ProductEditor/ProductsAdd';
import Add from '../../pages/Add/Add';
import UpdateEditor from '../EntityEditors/UpdateEditor';

import './App.scss'
import 'react-toastify/dist/ReactToastify.css';
import { Login } from '../../pages/Login/Login';
import { useAuth } from '../../hooks/use-auth';



function App() {
    const { isAuth } = useAuth()
    return (
        <>
            {isAuth ?
                <>
                    <main className="app-grid">
                        <Navigation />
                        <div>
                            <Header />
                            <Routes>
                                <Route path="/" element={<Dashboard />} />
                                <Route path="/add" element={<Add />} />
                                <Route path="/add/categories" element={<CategoryAdd />} />
                                <Route path="/add/subcategories" element={<SubcategoryAdd />} />
                                <Route path="/add/products" element={<ProductAdd />} />

                                <Route path="/catalog" element={<Catalog />} />
                                <Route path="/catalog/categories/:id" element={
                                    <UpdateEditor
                                        type='categories'
                                        inputs={['name']}
                                        image={true}
                                        selects={[]}
                                    />}
                                />

                                <Route path="/catalog/subcategories/:id" element={
                                    <UpdateEditor
                                        type='subcategories'
                                        inputs={['name']}
                                        image={false}
                                        selects={['category_id']}
                                    />}
                                />

                                <Route path="/catalog/products/:id" element={
                                    <UpdateEditor
                                        type='products'
                                        inputs={['name', 'description', 'weight', 'price']}
                                        image={true}
                                        selects={['category_id', 'subcategory_id']}
                                    />}
                                />
                            </Routes>
                        </div>
                    </main>
                </> :
                <Login />
            }
            <ToastContainer />

        </>
    )
}

export default App;
