import './Loader.scss'

function Loader() {
    return (
        <section className="loader">
            <span className="dot"></span>
            <span className="dot"></span>
            <span className="dot"></span>
            <span className="dot"></span>
        </section>
    );
}

export default Loader;