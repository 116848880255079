import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { addProduct } from '../../Products/productsThunks';
import { fetchCategories } from '../../Categories/categoriesThunks';
import { fetchSubcategoriesByCategory } from '../../Subcategories/subcategoriesThunks';
import { useDispatch, useSelector } from 'react-redux';

import { selectAll as selectAllCategories } from '../../Categories/CategoriesSlice';
import { selectAll as selectAllSubcategories } from '../../Subcategories/SubcategoriesSlice';
import Loader from '../../Loader/Loader';
import ImageCropper from '../../ImageCropper/ImageCropper';
import { v4 as uuidv4 } from 'uuid';


import '../EntityEditors.scss';


function ProductAdd() {
    const dispatch = useDispatch();
    const productsLoadingStatus = useSelector(state => state.products.productsLoadingStatus);
    const categories = useSelector(selectAllCategories);
    const subcategories = useSelector(selectAllSubcategories);

    const [productName, setProductName] = useState('');
    const [productDescription, setProductDescription] = useState('');
    const [productWeight, setProductWeight] = useState('');
    const [productPrice, setProductPrice] = useState('');
    const [productCategoryId, setProductCategoryId] = useState('');
    const [productSubcategoryId, setProductSubcategoryId] = useState('');
    const [img, setImg] = useState(null);

    useEffect(() => {
        if (categories.length === 0) {
            dispatch(fetchCategories());
        }
        // eslint-disable-next-line
    }, [categories]);



    useEffect(() => {
        if (productCategoryId) {
            dispatch(fetchSubcategoriesByCategory(productCategoryId));
        }
        // eslint-disable-next-line
    }, [productCategoryId]);

    const isChanged = () => {
        return productName !== '' && productCategoryId !== '' && productSubcategoryId !== ''
    }

    const onImageCropped = (croppedImage) => {
        setImg(croppedImage);
    };

    const onSubmitForm = (e) => {
        e.preventDefault();

        let newProduct = {
            name: productName,
            description: productDescription,
            weight: productWeight,
            price: productPrice,
            category_id: productCategoryId,
            subcategory_id: productSubcategoryId,
            img: img,
            img_name: img === null ? null : uuidv4()
        }

        const newProductJson = JSON.stringify(newProduct)

        dispatch(addProduct(newProductJson));
        setImg(null);
        setProductName('');
        setProductDescription('');
        setProductWeight('');
        setProductPrice('');
        setProductCategoryId('');
        setProductSubcategoryId('');
    }

    const onDeleteImage = () => {
        setImg(null);
    };

    if (productsLoadingStatus === 'loading') {
        return <Loader />;
    }

    if (productsLoadingStatus !== 'idle') {
        return <div>Error</div>;
    }

    return (
        <div className='editor'>
            <div className="editor-breadcrumbs">
                <Link className='editor-breadcrumbs-link' to="/add">Додати</Link>
                <span className='icon-arrow editor-breadcrumbs-icon'></span>
                <span className='editor-breadcrumbs-link'>Продукти</span>
            </div>
            <h2>Додати продукт</h2>
            <div className="editor-grid">
                <form className='editor-form' onSubmit={onSubmitForm}>
                    <div className='editor-form-cropper'>
                        <ImageCropper
                            onImageCropped={onImageCropped}
                        />
                    </div>
                    <label>
                        Назва
                        <input
                            type="text"
                            value={productName}
                            onChange={e => setProductName(e.target.value)}
                            required
                        />
                    </label>
                    <label>
                        Ціна
                        <input
                            type="text"
                            value={productPrice}
                            onChange={e => setProductPrice(e.target.value)}
                        />
                    </label>
                    <label className='editor-form-full'>
                        Опис
                        <textarea
                            value={productDescription}
                            onChange={e => setProductDescription(e.target.value)}
                        />
                    </label>

                    <label className='editor-form-full'>
                        Обсяг
                        <input
                            type="text"
                            value={productWeight}
                            onChange={e => setProductWeight(e.target.value)}
                        />
                    </label>
                    <label>
                        Категорія
                        <select
                            value={productCategoryId}
                            onChange={e => setProductCategoryId(e.target.value)}
                            required
                        >
                            <option value="">Оберіть категорію</option>
                            {categories.map(category => (
                                <option key={category.id} value={category.id}>
                                    {category.name}
                                </option>
                            ))}
                        </select>
                    </label>
                    <label>
                        Підкатегорія
                        <select
                            value={productSubcategoryId}
                            onChange={e => setProductSubcategoryId(e.target.value)}
                            required
                            disabled={!productCategoryId}
                        >
                            <option value="">Оберіть підкатегорію</option>
                            {subcategories.map(subcategory => (
                                <option key={subcategory.id} value={subcategory.id}>
                                    {subcategory.name}
                                </option>
                            ))}
                        </select>
                    </label>
                    <div className="editor-form-btns">
                        <button disabled={!isChanged()} type="submit" className='btn send-btn'>Додати продукт</button>
                    </div>
                </form>
                <div className='editor-img'>
                    {img !== null && (
                        <>
                            <span>Завантажене фото</span>
                            <img className='form-img' src={img} alt="Cropped" />
                            <button className='btn btn_small cancel-btn' onClick={onDeleteImage} type="button">
                                Видалити завантажене фото
                            </button>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}

export default ProductAdd;
