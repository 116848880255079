import { createAsyncThunk } from "@reduxjs/toolkit";
import useHttp from "../../hooks/http.hook";
import config from "../../config";

const _api = config.apiUrl;
const _media = config.mediaUrl;

export const getProductsCount = createAsyncThunk(
    'products/getProductsCount',
    () => {
        const { request } = useHttp();
        return request(`${_api}count/products`);
    }
)


export const fetchProductsBySubcategory = createAsyncThunk(
    'products/fetchProductsBySubcategory',
    async (subcategoryId) => {
        const { request } = useHttp();
        return await request(`${_api}products/subcategory/${subcategoryId}`);
    }
);

export const checkProductImage = createAsyncThunk(
    'products/fetchProductsImage',
    (id) => {
        const { request } = useHttp();
        return request(`${_media}products/check/${id}`);
    }
);


export const fetchProductById = createAsyncThunk(
    'products/fetchProductById',
    async (id) => {
        const { request } = useHttp();
        return await request(`${_api}products/${id}`);
    }
);

export const fetchProductsByIds = createAsyncThunk(
    'products/fetchProductsByIds',
    async (ids) => {
        const { request } = useHttp();
        return await request(`${_api}products/ids/${ids}`);
    }
);

export const addProduct = createAsyncThunk(
    'products/addProduct',
    async (newProduct) => {
        const { request } = useHttp();
        return await request(`${_api}products/add`, 'POST', newProduct);
    }
);

// Обновление продукта
export const updateProduct = createAsyncThunk(
    'products/updateProduct',
    async ({ id, updetedItemJson }) => {
        const { request } = useHttp();
        return await request(`${_api}products/${id}`, 'PUT', updetedItemJson);
    }
);

// Удаление продукта
export const deleteProduct = createAsyncThunk(
    'products/deleteProduct',
    async (id) => {
        const { request } = useHttp();
        return await request(`${_api}products/${id}`, 'DELETE');
    }
);
