import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchSubcategoriesByCategory } from "./subcategoriesThunks";

import SubcategoryItem from "./SubcategoryItem";
import { selectAll, setCurrentSubcategoryId } from "./SubcategoriesSlice";
import Loader from "../Loader/Loader";

import './Subcategories.scss'

function Subcategories() {
    const dispatch = useDispatch();
    const subcategories = useSelector(selectAll);
    const subcategoriesLoadingStatus = useSelector((state) => state.subcategories.subcategoriesLoadingStatus);
    const currentCategoryId = useSelector((state) => state.categories.currentCategoryId);
    const currentSubcategoryId = useSelector((state) => state.subcategories.currentSubcategoryId);

    useEffect(() => {
        dispatch(fetchSubcategoriesByCategory(currentCategoryId));
        // eslint-disable-next-line
    }, [currentCategoryId]);



    useEffect(() => {
        if (subcategories.length > 0) {
            const isCurrentSubcategoryValid = subcategories.some(subcategory => subcategory.id === currentSubcategoryId);
            if (!isCurrentSubcategoryValid) {
                dispatch(setCurrentSubcategoryId(subcategories[0].id));
            }
        } else {
            dispatch(setCurrentSubcategoryId(localStorage.getItem('currentSubcategoryId')));
        }
    }, [subcategories, currentSubcategoryId]);

    const renderCategories = (elements) => {
        if (elements.length === 0 && subcategoriesLoadingStatus !== 'loading') {
            return <h4>Поки немає підкатегорій</h4>;
        } else {
            return elements.map((elem) => {
                return (
                    <SubcategoryItem
                        key={elem.id}
                        id={elem.id}
                        name={elem.name} />
                );
            });
        }
    };

    const elements = renderCategories(subcategories);

    return (
        <section className="subcategories">
            <h3>Підкатегорії</h3>
            <div className="subcategories-grid">
                {subcategoriesLoadingStatus === 'loading' && <Loader />}
                {subcategoriesLoadingStatus === 'idle' && elements}
            </div>
        </section>
    );
}

export default Subcategories;
