import { Link } from 'react-router-dom';
import { useState } from 'react';
import { addCategory } from '../../Categories/categoriesThunks';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import Loader from '../../Loader/Loader';
import ImageCropper from '../../ImageCropper/ImageCropper';

import '../EntityEditors.scss'

function CategoryAdd() {
    const dispatch = useDispatch();
    const categoriesLoadingStatus = useSelector(state => state.categories.categoriesLoadingStatus);
    const [categoryName, setCategoryName] = useState('');
    const [img, setImg] = useState(null);


    const isChanged = () => {
        return categoryName !== ''

    }

    const onImageCropped = (croppedImage) => {
        setImg(croppedImage);
    };

    const onSubmitForm = (e) => {
        e.preventDefault();

        let newCategory = {
            name: categoryName,
            img: img,
            img_name: img === null ? null : uuidv4()
        }

        const newCategoryJson = JSON.stringify(newCategory);
        dispatch(addCategory(newCategoryJson));
        setImg(null);
        setCategoryName('');
    }

    const onDeleteImage = () => {
        setImg(null);
    };


    if (categoriesLoadingStatus === 'loading') {
        return <Loader />;
    }

    if (categoriesLoadingStatus !== 'idle') {
        return <div>Error</div>;
    }

    return (
        <div className='editor'>
            <div className="editor-breadcrumbs">
                <Link className='editor-breadcrumbs-link' to="/add">Додати</Link>
                <span className='icon-arrow editor-breadcrumbs-icon'></span>
                <span className='editor-breadcrumbs-link'>Категорії</span>
            </div>
            <h2>Додати категорію</h2>
            <div className="editor-grid">
                <form className='editor-form' onSubmit={onSubmitForm}>
                    <div className='editor-form-cropper'>
                        <ImageCropper
                            onImageCropped={onImageCropped}
                        />
                    </div>
                    <label>
                        Назва
                        <input
                            type="text"
                            value={categoryName}
                            onChange={e => setCategoryName(e.target.value)}
                            required
                        />
                    </label>
                    <div className="editor-form-btns">
                        <button disabled={!isChanged()} type="submit" className='btn send-btn'>Додати категорію</button>
                    </div>

                </form>
                <div className='editor-img'>
                    {img !== null && (
                        <>
                            <span>Завантажене фото</span>
                            <img className='form-img' src={img} alt="Cropped" />
                            <button className='btn btn_small cancel-btn' onClick={onDeleteImage} type="button">
                                Видалити завантажене фото
                            </button>
                        </>)}
                </div>
            </div>
        </div>
    );
}

export default CategoryAdd;
