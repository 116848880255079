import { configureStore } from '@reduxjs/toolkit';

import categories from '../components/Categories/CategoriesSlice';
import subcategories from '../components/Subcategories/SubcategoriesSlice';
import products from '../components/Products/ProductsSlice';
import modal from '../components/Modal/ModalSlice'
import userReducer from '../pages/Login/userSlice'

const store = configureStore({
    reducer: { categories, subcategories, products, modal, userReducer },
    middleware: getDefaultMiddleware => getDefaultMiddleware(),
    devTools: process.env.NODE_ENV !== 'production',
});

export default store;
