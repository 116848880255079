import { Link, useParams, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import config from '../../config';
import { fetchCategoryById, fetchCategories, updateCategory, deleteCategory, checkCategoryImage } from '../Categories/categoriesThunks';
import { fetchSubcategoryById, fetchSubcategoriesByCategory, updateSubcategory, deleteSubcategory } from '../Subcategories/subcategoriesThunks';
import { fetchProductById, updateProduct, deleteProduct, checkProductImage } from '../Products/productsThunks';

import { selectAll as selectAllCategories } from '../Categories/CategoriesSlice';
import { selectAll as selectAllSubategories } from '../Subcategories/SubcategoriesSlice';

import ImageCropper from '../ImageCropper/ImageCropper';

import baseImg from '../../images/base.jpeg';
import loadingGif from '../../images/loading.gif';

import './EntityEditors.scss'
import { v4 as uuidv4 } from 'uuid';
function UpdateEditor(props) {
    const mediaUrl = config.mediaUrl;
    const { type, inputs, image, selects } = props;
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [itemName, setItemName] = useState('');
    const [imgName, setImgName] = useState('');
    const fetchedItem = useSelector(state => state[type].fetchedItem);
    const [img, setImg] = useState(null);
    const categories = useSelector(selectAllCategories);
    const subcategories = useSelector(selectAllSubategories);
    const [currentCategoryId, setCurrentCategoryId] = useState(null);

    const labels = {
        name: 'Назва',
        price: 'Ціна',
        weight: 'Обсяг',
        description: 'Опис',
        category_id: 'Категорія',
        subcategory_id: 'Підкатегорія'
    };

    const [state, setState] = useState(
        inputs.reduce((acc, input) => {
            acc[input] = '';
            return acc;
        }, {})
    );

    useEffect(() => {
        switch (type) {
            case 'categories':
                dispatch(fetchCategoryById(id));
                break;
            case 'subcategories':
                dispatch(fetchSubcategoryById(id));
                break;
            case 'products':
                dispatch(fetchProductById(id));
                break;
            default:
                throw new Error('Invalid editor type');
        }
    }, [id]);

    useEffect(() => {
        if (fetchedItem !== null) {
            setItemName(fetchedItem.name);
            setImgName(fetchedItem.img_name);
            setState(prevState => {
                const updatedState = { ...prevState };
                inputs.forEach(input => {
                    updatedState[input] = fetchedItem[input];
                });
                return updatedState;
            });

            if (selects.includes('category_id')) {
                setState(prevState => ({
                    ...prevState,
                    category_id: fetchedItem.category_id
                }));
                setCurrentCategoryId(fetchedItem.category_id);
                if (categories.length === 0) {
                    dispatch(fetchCategories());
                }
            }
        }
    }, [fetchedItem]);

    useEffect(() => {
        if (currentCategoryId) {
            dispatch(fetchSubcategoriesByCategory(currentCategoryId));
        }
    }, [currentCategoryId]);

    const onImageCropped = (croppedImage) => {
        setImg(croppedImage);
    };

    const onDeleteImage = () => {
        setImg(null);
    };

    const isChanged = () => {
        if (img !== null) {
            return true;
        }
        if (fetchedItem) {
            for (let key in state) {
                if (state[key] !== fetchedItem[key]) {
                    return true;
                }
            }
            return false;
        } else {
            return false;
        }
    };

    const onSubmitForm = (e) => {
        e.preventDefault();

        let updetedItem = { ...state };

        if (img) {
            updetedItem.img = img;
            updetedItem.img_name = `${uuidv4()}`;
        }

        const updetedItemJson = JSON.stringify(updetedItem);

        let action;
        switch (type) {
            case 'categories':
                action = updateCategory({ id, updetedItemJson });
                break;
            case 'subcategories':
                action = updateSubcategory({ id, updetedItemJson });
                break;
            case 'products':
                action = updateProduct({ id, updetedItemJson });
                break;
            default:
                throw new Error('Invalid editor type');
        }
        dispatch(action);
        navigate('/catalog');
    };

    const onDeleteCategory = () => {
        let action;
        switch (type) {
            case 'categories':
                action = deleteCategory(id);
                break;
            case 'subcategories':
                action = deleteSubcategory(id);
                break;
            case 'products':
                action = deleteProduct(id);
                break;
            default:
                throw new Error('Invalid editor type');
        }
        dispatch(action);
        navigate('/catalog');
    };

    const onInputChange = (e) => {
        const { name, value } = e.target;
        setState(prevState => ({
            ...prevState,
            [name]: value
        }));

        if (name === 'category_id') {
            setCurrentCategoryId(value);
        }
    };

    const renderOptions = (select, categories, subcategories) => {
        switch (select) {
            case 'category_id':
                return categories.map(category => (
                    <option key={category.id} value={category.id}>
                        {category.name}
                    </option>
                ));
            case 'subcategory_id':
                return subcategories.map(subcategory => (
                    <option key={subcategory.id} value={subcategory.id}>
                        {subcategory.name}
                    </option>
                ));
            default:
                return null;
        }
    };

    const setBreadcrumbs = (type) => {
        switch (type) {
            case 'categories':
                return 'Категорії';
            case 'subcategories':
                return 'Підкатегорії';
            case 'products':
                return 'Продукція';
            default:
                throw new Error('Invalid editor type');
        }
    };

    return (
        <div className='editor'>
            <Link to='/catalog' className='back-btn'>
                <div className='back-btn-icon'>
                    <span className='icon-arrow'></span>
                </div>
                В Каталог
            </Link>
            <div className="editor-breadcrumbs">
                <Link className='editor-breadcrumbs-link' to="/catalog">Каталог</Link>
                <span className='icon-arrow editor-breadcrumbs-icon'></span>
                <span className='editor-breadcrumbs-link'>
                    {
                        setBreadcrumbs(type)
                    }
                </span>
                <span className='icon-arrow editor-breadcrumbs-icon'></span>
                <span className='editor-breadcrumbs-link'>{itemName}</span>
            </div>
            <div className="editor-grid">
                <form className='editor-form' onSubmit={onSubmitForm}>
                    <div className='editor-form-cropper'>
                        {
                            image && <ImageCropper
                                onImageCropped={onImageCropped}
                            />
                        }
                    </div>
                    {
                        inputs.map((input, i) => (
                            <label key={i}>
                                <span>{labels[input]}</span>
                                {input === 'description' ? (
                                    <textarea
                                        name={input}
                                        value={state[input]}
                                        onChange={onInputChange}
                                    />
                                ) : (
                                    <input
                                        name={input}
                                        value={state[input]}
                                        onChange={onInputChange}
                                    />
                                )}
                            </label>
                        ))
                    }
                    {
                        selects.map((select, i) => (
                            <label key={i}>
                                <span>{labels[select]}</span>
                                <select
                                    name={select}
                                    value={state[select]}
                                    onChange={onInputChange}
                                >
                                    {renderOptions(select, categories, subcategories)}
                                </select>
                            </label>
                        ))}
                    <div className="editor-form-btns">
                        <button onClick={onDeleteCategory} type="button" className='btn cancel-btn'>Видалити</button>
                        <button disabled={!isChanged()} type="submit" className='btn send-btn'>Зберегти зміни</button>
                    </div>

                </form>
                <div className='editor-img'>
                    {image && (
                        <>
                            {img === null ? (
                                <>
                                    <span>Поточне фото</span>
                                    <img src={imgName === null ? baseImg : `${mediaUrl}/public/${type}/${imgName}`} alt={state.name} />
                                </>
                            ) : (
                                <>
                                    <span>Нове фото</span>
                                    <img className='form-img' src={img} alt="Cropped" />
                                    <button className='btn btn_small cancel-btn' onClick={onDeleteImage} type="button">
                                        Видалити нове фото
                                    </button>
                                </>
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}

export default UpdateEditor;
