import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isOpen: false,
}

const modalSlice = createSlice({
    name: 'modal',
    initialState,
    reducers: {
        openModal: state => { state.isOpen = true },
        closeModal: state => { state.isOpen = false },
    }
})

const { reducer, actions } = modalSlice;

export default reducer;
export const {
    openModal,
    closeModal,
} = actions;
