import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchCategories } from "./categoriesThunks";

import CategoryItem from "./CategoryItem";
import { selectAll } from "./CategoriesSlice";
import Loader from "../Loader/Loader";

import './Categories.scss'

function Categories() {
    const dispatch = useDispatch();
    const categories = useSelector(selectAll);
    const categoriesLoadingStatus = useSelector((state) => state.categories.categoriesLoadingStatus);

    useEffect(() => {
        dispatch(fetchCategories());
        // eslint-disable-next-line
    }, [])


    const renderCategories = (elements) => {
        if (elements.length === 0 && categoriesLoadingStatus !== 'loading') {
            return <h2>Поки немає категорій</h2>
        } else {
            return elements.map((elem) => {
                return (
                    <CategoryItem
                        key={elem.id}
                        id={elem.id}
                        name={elem.name}
                        imgName={elem.img_name} />
                )
            })
        }
    }

    const elements = renderCategories(categories);

    return (
        <section className="categories">
            <div className="categories-grid">
                {categoriesLoadingStatus === 'loading' && <Loader />}
                {categoriesLoadingStatus === 'idle' && elements}
            </div>
        </section>
    );
}

export default Categories;