import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchProductsBySubcategory } from "./productsThunks";
import ProductItem from "./ProductsItem";
import { selectAll } from "./ProductsSlice";

import './Products.scss';
import Loader from "../Loader/Loader";

function Products() {
    const dispatch = useDispatch();
    const products = useSelector(selectAll);
    const productsLoadingStatus = useSelector((state) => state.products.productsLoadingStatus);
    const subcategoriesLoadingStatus = useSelector((state) => state.subcategories.subcategoriesLoadingStatus);
    const currentSubcategoryId = useSelector((state) => state.subcategories.currentSubcategoryId);
    const currentCategoryId = useSelector((state) => state.categories.currentCategoryId);


    useEffect(() => {
        renderProducts([])
        if (currentSubcategoryId) {
            dispatch(fetchProductsBySubcategory(currentSubcategoryId));
        } else {
            dispatch(fetchProductsBySubcategory(null));
        }
        // eslint-disable-next-line
    }, [currentSubcategoryId, currentCategoryId]);


    const renderProducts = (elements) => {
        if (elements.length === 0 && productsLoadingStatus !== 'loading') {
            return <h4>Поки немає продуктів</h4>;
        } else {
            return elements.map((elem) => {
                return (
                    <ProductItem
                        key={elem.id}
                        id={elem.id}
                        name={elem.name}
                        description={elem.description}
                        weight={elem.weight}
                        price={elem.price}
                        imgName={elem.img_name} />
                );
            });
        }
    };

    const elements = renderProducts(products);

    return (
        <section className="products">
            <div className="products-grid">
                <div className="products-row products-row_sticky">
                    <div className='products-col'>
                    </div>
                    <div className='products-col'>Назва</div>
                    <div className='products-col'>Обсяг</div>
                    <div className='products-col'>Опис</div>
                    <div className='products-col'>Ціна</div>
                    <div className='products-col'>Фото</div>
                    <div className='products-col'>Дії</div>
                </div>
                {productsLoadingStatus === 'loading' && <Loader />}
                {(productsLoadingStatus === 'idle' && subcategoriesLoadingStatus === 'idle') && elements}

            </div>
        </section>
    );
}

export default Products;
