import { useMemo, useState } from 'react';
import { NavLink } from 'react-router-dom';

import logoIcon from '../../images/logo-icon.png'
import './Navigation.scss';
import classNames from 'classnames';

const navLinks = [
    {
        title: 'Панель приладів',
        icon: 'icon-dashboard',
        path: '/'
    },
    {
        title: 'Каталог',
        icon: 'icon-catalog',
        path: '/catalog',
    },
    {
        title: 'Додати',
        icon: 'icon-add',
        path: '/add'
    },
    // {
    //     title: 'Відгуки',
    //     icon: 'icon-reviews',
    //     path: '/reviews'
    // },
    // {
    //     title: 'Вакансії',
    //     icon: 'icon-vacancies',
    //     path: '/vacancies'
    // }
];

function Navigation() {
    const [isOpen, setIsOpen] = useState(true)
    const links = useMemo(() => {
        return navLinks.map((elem, i) => (
            <li key={i}>
                <NavLink to={elem.path}>
                    <div className='nav-icon'>
                        <span className={elem.icon}></span>
                    </div>
                    <span>{elem.title}</span>
                </NavLink>
            </li>
        ));
        // eslint-disable-next-line
    }, [navLinks]);
    return (
        <nav className={classNames('nav', { close: isOpen })}>
            <div className='nav-header'>
                <button className='nav-close' onClick={() => setIsOpen(isOpen => !isOpen)}>
                    <div className="nav-close-btn"></div>
                </button>

                <div>GRILL ZONE</div>
                <img className='nav-logo' src={logoIcon} alt="logo" />

            </div>
            <ul>
                {links}
            </ul>
        </nav>
    );
}

export default Navigation;
