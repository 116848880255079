import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getAuth, signInWithEmailAndPassword, setPersistence, browserLocalPersistence, onAuthStateChanged } from "firebase/auth";
import { setUser } from './userSlice';
import { toast } from 'react-toastify';


import logoImg from '../../images/logo-icon.png'

import './Login.scss'

const Login = () => {
    const dispatch = useDispatch();
    const [email, setEmail] = useState('');
    const [pass, setPass] = useState('');
    const [password, setPassword] = useState("");
    const [type, setType] = useState('password');
    const [icon, setIcon] = useState('icon-eye');

    useEffect(() => {
        const auth = getAuth();

        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                dispatch(setUser({
                    email: user.email,
                    id: user.uid,
                    token: user.accessToken,
                }));
            }
        });


        return () => unsubscribe();
    }, [dispatch]);

    const handleToggle = () => {
        if (type === 'password') {
            setIcon('icon-eye');
            setType('text')
        } else {
            setIcon('icon-eye-off')
            setType('password')
        }
    }

    const handleLogin = (email, password) => {
        const auth = getAuth();

        setPersistence(auth, browserLocalPersistence)
            .then(() => {
                return signInWithEmailAndPassword(auth, email, password);
            })
            .then(({ user }) => {
                console.log(user);
                dispatch(setUser({
                    email: user.email,
                    id: user.uid,
                    token: user.accessToken,
                }));
            })
            .catch((error) => {
                toast.error("Неправильний логін або пароль");

            });
    }

    return (
        <div className='login'>
            <div className="login-content">
                <h1>Увійдіть у свій акаунт</h1>
                <img src={logoImg} alt="Grill Zone" />
                <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email"
                    required
                />


                <div className="login-pass">
                    <input
                        type={type}
                        name="password"
                        placeholder="Password"
                        value={pass}
                        onChange={(e) => setPass(e.target.value)}
                        required
                    />
                    <span onClick={handleToggle} className={icon}></span>
                </div>

                <button className='login-btn' onClick={() => handleLogin(email, pass)}>
                    Увійти
                </button>
            </div>
        </div>
    );
}

export { Login }
