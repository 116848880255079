import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentCategoryId } from './CategoriesSlice';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import config from '../../config';
import baseImg from '../../images/base.jpeg';

function CategoryItem(props) {
    const { id, name, imgName } = props;
    const dispatch = useDispatch();
    const currentCategoryId = useSelector(state => state.categories.currentCategoryId);
    const [imageSrc, setImageSrc] = useState(baseImg);
    const mediaUrl = config.mediaUrl;

    const onChangeCategory = (id) => {
        dispatch(setCurrentCategoryId(id));
    };

    return (
        <div className="categories-item-wrapper">
            <Link className="icon-edit edit-btn categories-item__edit-btn" to={`categories/${id}`} />
            <div
                onClick={() => onChangeCategory(id)}
                className={classNames('categories-item', { active: currentCategoryId == id })}>
                <img src={`${mediaUrl}public/categories/${imgName}`} alt={name} />
                <h3>{name}</h3>
            </div>
        </div>
    );
}

export default CategoryItem;
