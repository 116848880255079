import CategoryItem from '../Categories/CategoryItem'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { getCategoriesCount } from '../Categories/categoriesThunks'
import { getSubcategoriesCount } from '../Subcategories/subcategoriesThunks'
import { getProductsCount } from '../Products/productsThunks'

import './Statistics.scss'

import loadingGif from '../../icons/spinner.gif'

function Statistics() {
    const dispatch = useDispatch()
    const categoriesCount = useSelector(state => state.categories.categoriesCount)
    const subcategoriesCount = useSelector(state => state.subcategories.subcategoriesCount)
    const productsCount = useSelector(state => state.products.productsCount)

    useEffect(() => {
        dispatch(getCategoriesCount())
        dispatch(getSubcategoriesCount())
        dispatch(getProductsCount())
    }, [])

    let statisticsItems = [
        {
            title: 'Всього категорій',
            icon: 'icon-categories',
            count: categoriesCount === null ? <img src={loadingGif} /> : categoriesCount
        },
        {
            title: 'Всього підкатегорій',
            icon: 'icon-subcategories',
            count: subcategoriesCount === null ? <img src={loadingGif} /> : subcategoriesCount
        },
        {
            title: 'Всього позицій',
            icon: 'icon-products',
            count: productsCount === null ? <img src={loadingGif} /> : productsCount
        },
    ]


    const elements = statisticsItems.map((elem, i) => {
        return (
            <div key={i} className='statistics-item'>
                <div className="statistics-item-header">
                    <div className='statistics-icon'>
                        <span className={elem.icon}></span>
                    </div>
                    <span>{elem.title}</span>
                </div>
                <span className='statistics-count'>{elem.count}</span>
            </div>
        )
    })

    return (
        <section className="statistics">
            <div className="statistics-grid">
                {elements}
            </div>
        </section>
    );
}

export default Statistics;