export default async function getCroppedImg(imageSrc, pixelCrop, targetSize = 600) {
    const image = new Image();
    image.src = imageSrc;

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const { x, y, width, height } = pixelCrop;

    // Вычисляем соотношение сторон обрезанного изображения
    const aspectRatio = width / height;

    // Определяем конечные размеры изображения, сохраняя соотношение сторон
    let targetWidth, targetHeight;

    if (aspectRatio > 1) { // Если изображение горизонтальное
        targetWidth = targetSize;
        targetHeight = targetSize / aspectRatio;
    } else { // Если изображение вертикальное или квадратное
        targetHeight = targetSize;
        targetWidth = targetSize * aspectRatio;
    }

    canvas.width = targetWidth;
    canvas.height = targetHeight;

    return new Promise((resolve) => {
        image.onload = () => {
            ctx.fillStyle = 'white';
            ctx.fillRect(0, 0, canvas.width, canvas.height);

            // Рисуем изображение с учетом уменьшенного размера
            ctx.drawImage(
                image,
                x,
                y,
                width,
                height,
                0,
                0,
                targetWidth,
                targetHeight
            );

            const base64Image = canvas.toDataURL('image/jpeg');
            resolve(base64Image);
        };
    });
}
