import { useDispatch, useSelector } from "react-redux";
import { setCurrentSubcategoryId } from "./SubcategoriesSlice";
import classNames from "classnames";
import { Link } from "react-router-dom";


function CategoryItem(props) {
    const { id, name } = props
    const dispatch = useDispatch()
    const currentSubcategoryId = useSelector(state => state.subcategories.currentSubcategoryId);

    const onChangeSubcategory = (id) => {
        dispatch(setCurrentSubcategoryId(id))
    }

    return (
        <div onClick={() => onChangeSubcategory(id)} className={classNames('subcategories-item', { active: currentSubcategoryId == id })}>
            <span>{name}</span>
            <Link className="icon-edit edit-btn subcategories-item-edit-btn" to={`subcategories/${id}`} />
        </div>
    );
}

export default CategoryItem;