import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { addSubcategory } from '../../Subcategories/subcategoriesThunks';
import { useDispatch, useSelector } from 'react-redux';

import Loader from '../../Loader/Loader';

import '../EntityEditors.scss'
import { selectAll } from '../../Categories/CategoriesSlice';
import { fetchCategories } from '../../Categories/categoriesThunks';

function SubcategoryAdd() {
    const dispatch = useDispatch();
    const categoriesLoadingStatus = useSelector(state => state.categories.categoriesLoadingStatus);
    const categories = useSelector(selectAll);
    const subcategoriesLoadingStatus = useSelector(state => state.subcategories.subcategoriesLoadingStatus);

    const [subcategoryName, setSubcategoryName] = useState('');
    const [selectedCategoryId, setSelectedCategoryId] = useState('');

    const isChanged = () => {
        return subcategoryName !== '' && selectedCategoryId !== '';
    }

    const onSubmitForm = (e) => {
        e.preventDefault();

        let newSubcategory = {
            name: subcategoryName,
            category_id: selectedCategoryId
        }

        const newSubcategoryJson = JSON.stringify(newSubcategory);
        dispatch(addSubcategory(newSubcategoryJson));
        setSubcategoryName('');
        setSelectedCategoryId('');
    }

    useEffect(() => {
        if (categories.length === 0) {
            dispatch(fetchCategories());
        }
    }, [categories]);

    if (subcategoriesLoadingStatus === 'loading') {
        return <Loader />;
    }

    if (subcategoriesLoadingStatus !== 'idle') {
        return <div>Error</div>;
    }

    return (
        <div className='editor'>
            <div className="editor-breadcrumbs">
                <Link className='editor-breadcrumbs-link' to="/add">Додати</Link>
                <span className='icon-arrow editor-breadcrumbs-icon'></span>
                <span className='editor-breadcrumbs-link'>Підкатегорії</span>
            </div>
            <h2>Додати підкатегорію</h2>
            <div className="editor-grid">
                <form className='editor-form' onSubmit={onSubmitForm}>
                    <label>
                        Назва
                        <input
                            type="text"
                            value={subcategoryName}
                            onChange={e => setSubcategoryName(e.target.value)}
                            required
                        />
                    </label>
                    <label>
                        Категорія
                        <select
                            value={selectedCategoryId}
                            onChange={e => setSelectedCategoryId(e.target.value)}
                            required
                        >
                            <option value=''>
                                Виберіть категорію
                            </option>
                            {categories.map(category => (
                                <option key={category.id} value={category.id}>
                                    {category.name}
                                </option>
                            ))}
                        </select>
                    </label>
                    <div className="editor-form-btns">
                        <button disabled={!isChanged()} type="submit" className='btn send-btn'>Додати підкатегорію</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default SubcategoryAdd;
