import React, { useState, useCallback } from 'react';
import { Slider } from '@mui/material';
import Cropper from 'react-easy-crop';
import getCroppedImg from './cropImage';
import classNames from 'classnames';

import './ImageCropper.scss';

const minZoom = 0.1;

const ImageCropper = ({ onImageCropped }) => {
    const [imageSrc, setImageSrc] = useState(null);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [aspect] = useState(1 / 1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    // eslint-disable-next-line
    const [croppedImage, setCroppedImage] = useState(null);

    const onCropChange = (crop) => setCrop(crop);
    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => setCroppedAreaPixels(croppedAreaPixels), []);
    const onZoomChange = (zoom) => setZoom(zoom);

    const onSave = async () => {
        if (imageSrc && croppedAreaPixels) {
            const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels);
            setCroppedImage(croppedImage);
            onImageCropped(croppedImage);
            onCancel();
        }
    };

    const onImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => setImageSrc(reader.result);
            reader.readAsDataURL(file);
        }
        setZoom(1)
        setCrop({ x: 0, y: 0 })
        e.target.value = null;

    };

    const onCancel = () => {
        setImageSrc(null)
    }

    return (
        <div className={classNames('crop-wrapper')}>
            <div className={classNames("crop-container", { editing: imageSrc === null })}>
                <label htmlFor='imgInput' className='input-container'>
                    <input id='imgInput' type="file" accept="image/*" className='input-container__input' onChange={onImageChange} />

                    <div className='input-container__text'>
                        <span className='icon-upload input-container-icon'></span>
                        Завантажте фото
                        <p>Натисніть або перетяніть та завантажте</p>
                    </div>
                </label>
                {imageSrc && (
                    <div className='crop-container-edit-area'>
                        <Cropper
                            minZoom={minZoom}
                            image={imageSrc}
                            crop={crop}
                            zoom={zoom}
                            aspect={aspect}
                            restrictPosition={false}
                            onCropChange={onCropChange}
                            onCropComplete={onCropComplete}
                            onZoomChange={onZoomChange}
                        />
                        <div className="controls">
                            <Slider
                                value={zoom}
                                min={minZoom}
                                max={10}
                                step={0.01}
                                aria-labelledby="Zoom"
                                onChange={(e, newZoom) => onZoomChange(newZoom)}
                                className="slider"
                            />

                            <div className="controls-btns">
                                <button type='button' onClick={onSave} className="btn send-btn">
                                    Зберегти
                                </button>
                                <button type='button' onClick={onCancel} className="btn cancel-btn">
                                    Відмінити
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div >
    );
};

export default ImageCropper;
