import './Header.scss';
import { getAuth, signOut } from "firebase/auth";
import { useDispatch } from 'react-redux';
import { removeUser } from '../../pages/Login/userSlice';

function Header() {
    const dispatch = useDispatch();

    const handleLogout = () => {
        const auth = getAuth();
        signOut(auth).then(() => {
            dispatch(removeUser());
        }).catch((error) => {
            console.error('Ошибка выхода:', error);
        });
    }

    return (
        <header className='header'>
            <div className='header-title'>
                <h1>Admin</h1>
                <span>v.0.1 Beta</span>
            </div>
            <div className='header-btns'>
                {/* <button>
                    <span className='icon-search'></span>
                </button>
                <button>
                    <span className='icon-message'></span>
                </button> */}
                <a target='_blank' rel="noreferrer" href="https://www.grill-zone-cafe.com/">
                    На сайт
                    <span className='icon-link'></span>
                </a>
                <a onClick={handleLogout}>
                    Вийти
                    <span className='icon-logout'></span>
                </a>
            </div>
        </header>
    );
}

export default Header;
