import { createAsyncThunk } from "@reduxjs/toolkit";
import config from "../../config";
import useHttp from "../../hooks/http.hook";

const _api = config.apiUrl;
const _media = config.mediaUrl;

export const getCategoriesCount = createAsyncThunk(
    'categories/getCategoriesCount',
    () => {
        const { request } = useHttp();
        return request(`${_api}count/categories`);
    }
)

export const fetchCategories = createAsyncThunk(
    'categories/fetchCategories',
    () => {
        const { request } = useHttp();
        return request(`${_api}categories/all`);
    }
)

export const fetchCategoryById = createAsyncThunk(
    'categories/fetchCategoryById',
    (categoryId) => {
        const { request } = useHttp();
        return request(`${_api}categories/${categoryId}`);
    }
)

export const addCategory = createAsyncThunk(
    'categories/addCategory',
    (newCategory) => {
        const { request } = useHttp();
        return request(`${_api}categories/add`, 'POST', newCategory)
    }
)

export const deleteCategory = createAsyncThunk(
    'categories/deleteCategory',
    (id) => {
        const { request } = useHttp();
        return request(`${_api}categories/${id}`, 'DELETE')
    }
)

export const updateCategory = createAsyncThunk(
    'categories/updateCategory',
    ({ id, updetedItemJson }) => {
        console.log(updetedItemJson)
        const { request } = useHttp();
        return request(`${_api}categories/${id}`, 'PUT', updetedItemJson);
    }
)