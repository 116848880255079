import { createSlice, createEntityAdapter } from "@reduxjs/toolkit";
import { fetchProductsBySubcategory, fetchProductsByIds, addProduct, deleteProduct, updateProduct, fetchProductById, getProductsCount } from './productsThunks';
import { toast } from 'react-toastify';

const productsAdapter = createEntityAdapter();

const initialState = productsAdapter.getInitialState({
    productsLoadingStatus: 'idle',
    fetchedItem: null,
    productDeleteStatus: 'idle',
    productsCount: null,
});

const productsSlice = createSlice({
    name: 'products',
    initialState,
    reducers: {
        setProductDeleteStatus: (state, action) => {
            state.productDeleteStatus = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getProductsCount.pending, state => {
                state.productsCount = null;
            })
            .addCase(getProductsCount.fulfilled, (state, action) => {
                state.productsCount = action.payload.count;
            })

            .addCase(getProductsCount.rejected, state => {
                toast.warning('Помилка при отриманні загальної кількості продукції')
            })
            // Fetch all products by subcategory
            .addCase(fetchProductsBySubcategory.pending, state => {
                state.productsLoadingStatus = 'loading';
            })
            .addCase(fetchProductsBySubcategory.fulfilled, (state, action) => {
                state.productsLoadingStatus = 'idle';
                productsAdapter.setAll(state, action.payload);
            })
            .addCase(fetchProductsBySubcategory.rejected, state => {
                state.productsLoadingStatus = 'error';
                toast.error("При завантаженні продуктів сталася помилка");
            })

            // Fetch product by ID
            .addCase(fetchProductById.pending, state => {
                state.productsLoadingStatus = 'loading';
            })
            .addCase(fetchProductById.fulfilled, (state, action) => {
                state.productsLoadingStatus = 'idle';
                state.fetchedItem = action.payload
            })
            .addCase(fetchProductById.rejected, state => {
                state.productsLoadingStatus = 'error';
                toast.error("При завантаженні продукта сталася помилка");
            })

            // Fetch products by IDs
            .addCase(fetchProductsByIds.pending, state => {
                state.productsLoadingStatus = 'loading';
            })
            .addCase(fetchProductsByIds.fulfilled, (state, action) => {
                state.productsLoadingStatus = 'idle';
                state.fetchedProduct = action.payload;
            })
            .addCase(fetchProductsByIds.rejected, state => {
                state.productsLoadingStatus = 'error';
                toast.error("Помилка завантаження продуктів");
            })

            // Update product
            .addCase(updateProduct.pending, state => {
                state.productsLoadingStatus = 'loading';
            })
            .addCase(updateProduct.fulfilled, (state, action) => {
                state.productsLoadingStatus = 'idle';
                const { id, name, category_id, subcategory_id, description, weight, price } = action.payload.data;
                productsAdapter.updateOne(state, { id, changes: { name, category_id, subcategory_id, description, weight, price } });
                state.fetchedProduct = action.payload.data;
                toast.success("Продукт змінено");
            })
            .addCase(updateProduct.rejected, state => {
                state.productsLoadingStatus = 'error';
                toast.error("Щось пішло не так");
            })

            // Delete product
            .addCase(deleteProduct.pending, state => {
                state.productDeleteStatus = 'loading';
            })
            .addCase(deleteProduct.fulfilled, (state, action) => {
                state.productDeleteStatus = 'success';
                productsAdapter.removeOne(state, action.payload.id);
                toast.success("Продукт видалено успішно");
            })
            .addCase(deleteProduct.rejected, state => {
                state.productDeleteStatus = 'error';
                toast.error("Помилка видалення продукту");
            })
            // Add product
            .addCase(addProduct.pending, state => {
                state.productsLoadingStatus = 'loading';
            })
            .addCase(addProduct.fulfilled, (state, action) => {
                state.productsLoadingStatus = 'idle';
                productsAdapter.addOne(state, action.payload.data);
                toast.success("Продукт успішно додано");
            })
            .addCase(addProduct.rejected, state => {
                state.productsLoadingStatus = 'error';
                toast.error("Помилка додавання продукту");
            });
    },
});

const { reducer, actions } = productsSlice;

export default reducer;

export const {
    setProductDeleteStatus,
} = actions;

export const {
    selectAll,
} = productsAdapter.getSelectors(state => state.products);
