import { createAsyncThunk } from "@reduxjs/toolkit";
import useHttp from "../../hooks/http.hook";
import config from "../../config";

const _api = config.apiUrl;

export const getSubcategoriesCount = createAsyncThunk(
    'subcategories/getSubcategoriesCount',
    () => {
        const { request } = useHttp();
        return request(`${_api}count/subcategories`);
    }
)


// Получение подкатегорий по ID категории
export const fetchSubcategoriesByCategory = createAsyncThunk(
    'subcategories/fetchSubcategoriesByCategory',
    async (categoryId) => {
        const { request } = useHttp();
        return await request(`${_api}subcategories/category/${categoryId}`);
    }
);

// Получение подкатегории по ID
export const fetchSubcategoryById = createAsyncThunk(
    'subcategories/fetchSubcategoryById',
    async (id) => {
        const { request } = useHttp();
        return await request(`${_api}subcategories/${id}`);
    }
);


export const addSubcategory = createAsyncThunk(
    'subcategories/addSubcategory',
    async (newSubcategory) => {
        const { request } = useHttp();
        return await request(`${_api}subcategories/add`, 'POST', newSubcategory);
    }
);


export const updateSubcategory = createAsyncThunk(
    'subcategories/updateSubcategory',
    async ({ id, updetedItemJson }) => {
        const { request } = useHttp();
        return await request(`${_api}subcategories/${id}`, 'PUT', updetedItemJson);
    }
);

// Удаление подкатегории
export const deleteSubcategory = createAsyncThunk(
    'subcategories/deleteSubcategory',
    async (id) => {
        const { request } = useHttp();
        return await request(`${_api}subcategories/${id}`, 'DELETE');
    }
);
