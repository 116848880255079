import { createSlice, createEntityAdapter } from "@reduxjs/toolkit";
// eslint-disable-next-line 
import { fetchCategories, fetchCategoryById, addCategory, deleteCategory, updateCategory, getCategoriesCount } from './categoriesThunks';
import { toast } from 'react-toastify';

const categoriesAdapter = createEntityAdapter()

const initialState = categoriesAdapter.getInitialState({
    categoriesLoadingStatus: 'idle',
    currentCategoryId: localStorage.getItem('currentCategoryId') || null,
    fetchedItem: null,
    categoryDeleteStatus: 'idle',
    categoriesCount: null,
})

const categoriesSlice = createSlice({
    name: 'categories',
    initialState,
    reducers: {
        setCurrentCategoryId: (state, action) => {
            state.currentCategoryId = action.payload
            localStorage.setItem('currentCategoryId', action.payload)
        },
        setCategoryDeleteStatus: (state, action) => {
            state.categoryDeleteStatus = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCategoriesCount.pending, state => {
                state.categoriesCount = null;
            })
            .addCase(getCategoriesCount.fulfilled, (state, action) => {
                state.categoriesCount = action.payload.count;
            })

            .addCase(getCategoriesCount.rejected, state => {
                toast.warning('Помилка при отриманні загальної кількості категорій')
            })

            .addCase(fetchCategories.pending, state => { state.categoriesLoadingStatus = 'loading' })

            .addCase(fetchCategories.fulfilled, (state, action) => {
                state.categoriesLoadingStatus = 'idle';
                categoriesAdapter.setAll(state, action.payload);

                console.log(action.payload)
                if (state.currentCategoryId === null && action.payload.length > 0) {
                    state.currentCategoryId = action.payload[0].id;
                }
            })
            .addCase(fetchCategories.rejected, state => {
                state.categoriesLoadingStatus = 'error'
                toast.error("При завантаженні категорій сталася помилка");
            })


            .addCase(updateCategory.pending, state => {
                state.categoriesLoadingStatus = 'loading';
            })

            .addCase(updateCategory.fulfilled, (state, action) => {
                state.categoriesLoadingStatus = 'idle';
                state.fetchedItem = action.payload.data;
                toast.success("Категорію змінено");
            })

            .addCase(updateCategory.rejected, (state, action) => {
                toast.error("Щось пішто не так");
            })

            .addCase(fetchCategoryById.pending, state => {
                state.categoriesLoadingStatus = 'loading';

            })

            .addCase(fetchCategoryById.fulfilled, (state, action) => {
                state.categoriesLoadingStatus = 'idle';
                state.fetchedItem = action.payload;
            })

            .addCase(deleteCategory.pending, state => {
                state.categoryDeleteStatus = 'loading';
            })

            .addCase(deleteCategory.fulfilled, state => {
                state.categoryDeleteStatus = 'idle';
                toast.success("Категорія видалена успішно");
            })
            .addCase(deleteCategory.rejected, state => {
                state.categoryDeleteStatus = 'error';
                toast.error("Помилка видалення категорії");
            })

            .addCase(addCategory.pending, state => {
                state.categoriesLoadingStatus = 'loading';
            })

            .addCase(addCategory.fulfilled, state => {
                state.categoriesLoadingStatus = 'idle';
                toast.success("Категорія успішно додана");
            })
            .addCase(addCategory.rejected, state => {
                state.categoriesLoadingStatus = 'error';
                toast.error("Помилка додавання категорії");
            })
    }
})

const { reducer, actions } = categoriesSlice;

export default reducer;

export const {
    setCurrentCategoryId,
    setCategoryDeleteStatus,
} = actions;

export const {
    selectAll
} = categoriesAdapter.getSelectors(state => state.categories)
