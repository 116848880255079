import { Link } from 'react-router-dom';
import './Add.scss'

function Add() {
    return (
        <div className="add">
            <div className="add-grid">
                {/* <Link className="add-item" to='/add/banners'>
                    <span className='icon-banners add-item-icon'></span>
                    Додати банер
                </Link> */}
                <Link className="add-item" to='/add/categories'>
                    <span className='icon-categories add-item-icon'></span>
                    Додати категорію
                </Link>
                <Link className="add-item" to='/add/subcategories'>
                    <span className='icon-subcategories add-item-icon'></span>
                    Додати підкатегорію
                </Link>
                <Link className="add-item" to='/add/products'>
                    <span className='icon-products add-item-icon'></span>
                    Додати продукцію
                </Link>
            </div>
        </div>
    );
}

export default Add;