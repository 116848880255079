import { createSlice, createEntityAdapter } from "@reduxjs/toolkit";
import { fetchSubcategoriesByCategory, fetchSubcategoryById, addSubcategory, deleteSubcategory, updateSubcategory, getSubcategoriesCount } from './subcategoriesThunks';
import { toast } from 'react-toastify';

const subcategoriesAdapter = createEntityAdapter();

const initialState = subcategoriesAdapter.getInitialState({
    subcategoriesLoadingStatus: 'idle',
    currentSubcategoryId: parseInt(localStorage.getItem('currentSubcategoryId')) || null,
    fetchedItem: null,
    subcategoryDeleteStatus: 'idle',
    subcategoriesCount: null,

});

const subcategoriesSlice = createSlice({
    name: 'subcategories',
    initialState,
    reducers: {
        setCurrentSubcategoryId: (state, action) => {

            state.currentSubcategoryId = action.payload;
            localStorage.setItem('currentSubcategoryId', action.payload);
        },
        setSubcategoryDeleteStatus: (state, action) => {
            state.subcategoryDeleteStatus = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getSubcategoriesCount.pending, state => {
                state.subcategoriesCount = null;
            })
            .addCase(getSubcategoriesCount.fulfilled, (state, action) => {
                state.subcategoriesCount = action.payload.count;
            })

            .addCase(getSubcategoriesCount.rejected, state => {
                toast.warning('Помилка при отриманні загальної кількості підкатегорій')
            })
            .addCase(fetchSubcategoriesByCategory.pending, state => {
                state.subcategoriesLoadingStatus = 'loading';
            })
            .addCase(fetchSubcategoriesByCategory.fulfilled, (state, action) => {
                state.subcategoriesLoadingStatus = 'idle';
                subcategoriesAdapter.setAll(state, action.payload);
                if (state.currentSubcategoryId === null && action.payload.length > 0) {
                    state.currentSubcategoryId = action.payload[0].id;
                }
            })
            .addCase(fetchSubcategoriesByCategory.rejected, state => {
                state.subcategoriesLoadingStatus = 'error';
                toast.error("При завантаженні підкатегорій сталася помилка");
            })

            // Fetch single subcategory by ID
            .addCase(fetchSubcategoryById.pending, state => {
                state.subcategoriesLoadingStatus = 'loading';
            })
            .addCase(fetchSubcategoryById.fulfilled, (state, action) => {
                state.subcategoriesLoadingStatus = 'idle';
                state.fetchedItem = action.payload;
            })
            .addCase(fetchSubcategoryById.rejected, state => {
                state.subcategoriesLoadingStatus = 'error';
                toast.error("Помилка завантаження підкатегорії");
            })

            // Update subcategory
            .addCase(updateSubcategory.pending, state => {
                state.subcategoriesLoadingStatus = 'loading';
            })
            .addCase(updateSubcategory.fulfilled, (state, action) => {
                state.subcategoriesLoadingStatus = 'idle';
                state.fetchedSubcategory = action.payload.data;
                toast.success("Підкатегорію змінено");
            })
            .addCase(updateSubcategory.rejected, state => {
                state.subcategoriesLoadingStatus = 'error';
                toast.error("Щось пішло не так");
            })

            // Delete subcategory
            .addCase(deleteSubcategory.pending, state => {
                state.subcategoryDeleteStatus = 'loading';
            })
            .addCase(deleteSubcategory.fulfilled, state => {
                state.subcategoryDeleteStatus = 'idle';
                toast.success("Підкатегорію видалено успішно");
            })
            .addCase(deleteSubcategory.rejected, state => {
                state.subcategoryDeleteStatus = 'error';
                toast.error("Помилка видалення підкатегорії");
            })

            // Add subcategory
            .addCase(addSubcategory.pending, state => {
                state.subcategoriesLoadingStatus = 'loading';
            })
            .addCase(addSubcategory.fulfilled, state => {
                state.subcategoriesLoadingStatus = 'idle';
                toast.success("Підкатегорію успішно додано");
            })
            .addCase(addSubcategory.rejected, state => {
                state.subcategoriesLoadingStatus = 'error';
                toast.error("Помилка додавання підкатегорії");
            });
    },
});

const { reducer, actions } = subcategoriesSlice;

export default reducer;

export const {
    setSubcategoryDeleteStatus,
    setCurrentSubcategoryId,
} = actions;

export const {
    selectAll,
} = subcategoriesAdapter.getSelectors(state => state.subcategories);
